import React from 'react'
import { Routes, Route } from "@front/squirtle"
import OrderScheduleList from './List'

const OrderSchedulePage = (props) => {
  return (
    <Routes>
      <Route index element={<OrderScheduleList />} />
    </Routes>
  )
}

export default React.memo(OrderSchedulePage)
