import React, { useCallback } from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'

const OrderSchedule = props => {
  const handleRowClick = useCallback((rowId, row) => window.open(`/order/${rowId}`), [])
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])

  return (
    <Box className={'safearea'}>
      <Grid container justifyContent='center'>
        <Grid item xs={11}>
          <SearchFrame
            model_name={'order'}
            populate={[
              'payment_type',
              'channel',
              'job.payment',
              'source.poi',
              'destination.poi',
              'commercial_package',
              'do.commercial_formula',
              'do.customerinfo',
              'do.auth.application',
              'do.info',
              'assigned_transport.info',
              'current_target.eta',
              'loads',
              'service.contract.company',
              'service.contract.formula',
              'info'
            ]}
            sort={['requestedAt DESC']}
            allowCreate={false}
          >
            <SearchForm
              submitOnMount
              // filterToForm={filterToForm}
              formToFilter={useCallback(Callbacks.formToFilterHandler(), [])}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              tableProps={{
                onRowClick: handleRowClick
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(OrderSchedule)
