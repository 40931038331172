import React, { useCallback, useState } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography, Collapse } from '@mui/material'
import { TextField, ModelAutocompleteField, ModelMenuSelectorField } from '@front/squirtle'

import CPUtils from '@front/squirtle/utils/CPUtils'


const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs><TextField name={'order_client_id'} label={I18n.t('order.id.alter')} /></Grid>
    <Grid item xs><TextField name={'do.auth.gsm'} label={I18n.t('phone.label', { count: 1 })} /></Grid>
    <Grid item xs><TextField name={'do.customerinfo.client_number'} label={I18n.t('user.id')} /></Grid>
    <Grid item xs><TextField name={'do.customerinfo.name'} label={I18n.t('designation')} /></Grid>
    <Grid item xs><TextField name={'do.info.first_name'} label={I18n.t('name.first_name', { count: 1 })} /></Grid>
    <Grid item xs><TextField name={'do.info.last_name'} label={I18n.t('name.label', { count: 1 })} /></Grid>
  </Grid>

const Row2 = ({ company, contract }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'company'}
        label={I18n.t('company.label', { count: 1 })}
        model_name={'company'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          mutator: elem => ({ label: `${_.get(elem, 'client_id')} - ${_.get(elem, 'name')}`, ...elem })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'contract'}
        label={I18n.t('contract.name_client_id')}
        model_name={'companycontract'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          forced_filter: { company },
          validate_filter: useCallback(() => !!company, [company]),
          watchers: [company],
          mutator: companycontract => ({ label: `${_.get(companycontract, 'client_id')} - ${_.get(companycontract, 'name')}`, ...companycontract })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs >
      <ModelAutocompleteField
        name={'service'}
        label={I18n.t('companyservice.code')}
        model_name={'companyservice'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          forced_filter: { contract },
          validate_filter: useCallback(() => !!contract, [contract]),
          watchers: [contract],
          mutator: companyservice => ({ label: `${_.get(companyservice, 'client_id')} - ${_.get(companyservice, 'name')}`, ...companyservice })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='commercial_formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='commercial_package.commercial_package_id'
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
  </Grid>

const SearchForm = ({ initial_toggle, ...rest }) => {
  const [toggle, setToggle] = useState(initial_toggle)
  return (
    <Box sx={{
      boxShadow: 3,
      color: 'primary.main',
      '&:hover': !!toggle ? undefined : {
        backgroundColor: 'primary.main',
        color: 'extraWhite'
      },
    }}
    >
      <Box onClick={() => setToggle(!toggle)}>
        <Box sx={{ m: 1, p: 1 }}>
          <Typography variant={'h5'} color='inherit'> {I18n.t('order.search')} </Typography>
        </Box>
      </Box>
      <Collapse in={!!toggle}>
        <Box p={1}>
          <Grid container rowSpacing={5}>
            <Grid container item justifyContent={'center'} spacing={1}>
              <Row1 {...rest} />
              <Row2 {...rest} />
            </Grid>
          </Grid>
        </Box >
      </Collapse>
    </Box >
  )
}

export default React.memo(SearchForm)
