import React from 'react'
import { hooks, I18n, CDNSVG } from '@front/volcanion'

import GeoUtils from '@front/volcanion/utils/geo'
import { TimeUtils } from '@front/volcanion/utils'

import moment from 'moment'

import { OrderLink } from '@abra/elements'
import { TableCell, Box } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const isB2B = !!row?.service
  const { ORDER_LIST_SOURCE_FORMAT, ORDER_LIST_DESTINATION_FORMAT } = hooks.useRelayConstants(['ORDER_LIST_SOURCE_FORMAT', 'ORDER_LIST_DESTINATION_FORMAT',])

  const requestedAt = moment.utc(TimeUtils.getDetailsMomentFront(row?.requestedAt, row?.source)).local().format('DD/MM/YYYY HH:mm')
  return (
    <>
      <TableCell size='small' padding='none'>
        <CDNSVG sx={{ color: 'primary.main', fontSize: 18 }} name={!!isB2B ? 'account-b2b' : 'account-b2c'} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <OrderLink record_id={row?.order_id} fontSize={13} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <Box display='flex' alignItems='center' ml={1}>
          <TextCell fontSize={13} value={requestedAt} />
        </Box>
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={row?.commercial_package?.name_translated} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? row?.service?.contract?.formula.name : row?.do?.commercial_formula?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? row?.service?.contract?.company?.name : row?.do?.customerinfo?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} textAlign='start' value={GeoUtils.getSpecificFormat(row?.source, ORDER_LIST_SOURCE_FORMAT || 'friendly')} />
        <TextCell fontSize={13} textAlign='start' value={GeoUtils.getSpecificFormat(row?.destination, ORDER_LIST_DESTINATION_FORMAT || 'friendly')} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
