class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      console.log('pauline filter to form')
      return {
        order_schedule: { '!': null },
        schedule_index: 0,
        // ...filter
      }
    }
  }
  static formToFilterHandler() {

    return function formToFilter(values, extra, meta, state) {
      console.log('pauline form to filter')

      _.merge(values, {
        order_schedule: { '!': null },
        schedule_index: 0,
      })

      return values
    }
  }
}

export default Callbacks
